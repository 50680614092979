<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: 'App',
  /*   mounted() {
    axios
      .get('api/check-auth' + window.location.pathname)
      .then((response) => {})
      .catch((error) => {
        if (error.response.status === 401) {
          this.$store.commit('setAuthentication', false);
          this.$store.commit('setUser', null);
          this.$router.push({ name: 'Login' });
        }
      });
  }, */
};
</script>

<style lang="scss">
// Import Main styles for this application
@import 'assets/scss/style';
</style>
